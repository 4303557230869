import { ExpandableText, PersonSignature } from '@components';
import { Box } from '@mui/material';

import { ICandidateDetails } from '@types';

export const CandidateDetailsFeeback: React.FC<{ data: ICandidateDetails }> = ({
  data,
}) => {
  if (!data.feedback?.feedback) return null;

  return (
    <ExpandableText text={data.feedback.feedback}>
      {data.feedback?.reviewer && (
        <Box mt={3}>
          <PersonSignature person={data.feedback?.reviewer} />
        </Box>
      )}
    </ExpandableText>
  );
};
