import React from 'react';
import { Box, Fade } from '@mui/material';
import { fadeInDuration } from '../../../theme/variables';
import { Loader } from '../Loader/Loader';

interface IPageWrapperProps {
  children: React.ReactNode;
  isLoading?: boolean;
}

export const PageWrapper: React.FC<IPageWrapperProps> = ({
  children,
  isLoading = false,
}) => {
  return (
    <>
      {isLoading && <Loader />}
      <Fade in={true} timeout={fadeInDuration} mountOnEnter unmountOnExit>
        <Box>{children}</Box>
      </Fade>
    </>
  );
};
