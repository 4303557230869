import { useMemo } from 'react';
import { uniqBy } from 'lodash';

import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { CountryFlag } from 'components';

import { CandidateListAction } from './components/CandidateListAction';
import { CandidateStatusInfo } from './components/CandidateStatusInfo';
import { IPositionCandidate, IPositionWithCandidates } from '@types';
import { pluralize } from 'utils/string';

const tableHeaders = [
  {
    label: 'Position / Candidate',
    width: '20%',
    align: 'left',
  },
  {
    label: 'Seniority',
    width: '9%',
    align: 'left',
  },
  {
    label: 'Main stack',
    width: '20%',
    align: 'left',
  },
  {
    label: 'Matching',
    width: '17%',
    align: 'left',
  },
  {
    label: 'Latest updates',
    width: '18%',
    align: 'left',
  },
  {
    label: 'Next steps',
    width: '16%',
    align: 'left',
  },
];

export const CandidatesList: React.FC<{
  data: IPositionWithCandidates[];
  onCandidateClick: (id: string) => void;
}> = ({ data, onCandidateClick }) => {
  const candidates: Array<IPositionCandidate & { positions: string[] }> =
    useMemo(
      () =>
        uniqBy(
          data.reduce(
            (acc, val) => acc.concat(val.candidates),
            [] as IPositionCandidate[],
          ),
          'id',
        ).map((candidate) => ({
          ...candidate,
          positions: data
            .filter((position) =>
              position.candidates.some(
                (positionCandidate) => positionCandidate.id === candidate.id,
              ),
            )
            .map((position) => position.name),
        })),
      [data],
    );

  if (!candidates.length) {
    return (
      <Stack
        gap="0.5rem"
        flexDirection="column"
        py="3rem"
        px="5rem"
        bgcolor="background.backgroundPrimary"
        alignItems="center"
        borderRadius="1rem"
        my="3.5rem"
        mx="auto"
      >
        <img
          src={
            'https://ae-cp-static-files.s3.eu-central-1.amazonaws.com/client-dashboard/team.png'
          }
          alt="team"
          width="172px"
          height="172px"
        />
        <Typography variant="h2" textAlign="center">
          We’re pre-screening candidates for you
        </Typography>
        <Typography variant="h5" color="text.secondary" textAlign="center">
          Please expect an email from us once we have suitable matches
        </Typography>
      </Stack>
    );
  }

  return (
    <TableContainer>
      <Table stickyHeader sx={{ tableLayout: 'auto', width: '100%' }}>
        <TableHead>
          <TableRow>
            {tableHeaders.map((cell, idx) => {
              const width = cell.width;
              const align = cell.align;

              return (
                <TableCell
                  key={idx}
                  sx={(theme) => ({
                    padding: '0.4rem 0 0 1rem',
                    width: width,
                    height: '2rem',
                    verticalAlign: 'top',
                    border: 'none',
                    textAlign: align,
                    color: theme.palette.text.secondary,
                  })}
                >
                  {cell.label}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {candidates.map((candidate) => {
            const candidatePositionCount = candidate.positions.length;
            return (
              <TableRow
                key={candidate.id}
                onClick={() => onCandidateClick(candidate.id)}
                sx={(theme) => ({
                  cursor: 'pointer',
                  '&:hover': { background: theme.palette.highlight.actionable },
                })}
              >
                <TableCell>
                  <Box display="flex" alignItems="center">
                    <CountryFlag country={candidate.country} sx={{ mr: 1 }} />
                    <Typography
                      variant="body1"
                      color="text.link"
                      sx={{ textDecoration: 'underline' }}
                    >{`${candidate.firstName} ${candidate.lastName}`}</Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">{candidate.seniority}</Typography>
                </TableCell>
                <TableCell>
                  <Box display="flex" gap={0.5} flexWrap="wrap">
                    {candidate.skills?.map((skill) => (
                      <Box
                        component="span"
                        sx={(theme) => ({
                          background:
                            theme.palette.background.backgroundPrimary,
                          padding: '4px 8px 6px 8px',
                          borderRadius: '4px',
                          textWrap: 'nowrap',
                          fontSize: '0.825rem',
                        })}
                        key={skill}
                      >
                        {skill}
                      </Box>
                    ))}
                  </Box>
                </TableCell>
                <TableCell>
                  <Box display="flex" gap={1} alignItems="center">
                    <Typography variant="body1" color="text.secondary">
                      {pluralize(candidatePositionCount, 'position')}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <CandidateStatusInfo candidate={candidate} />
                </TableCell>
                <TableCell>
                  <CandidateListAction candidate={candidate} />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
