import { CandidateStatus } from '@constants';
import { isAfter } from 'date-fns';
import { DateTimeRange } from '@types';

export const canRescheduleOrCancelCall = (
  candidateStatus: CandidateStatus | null | undefined,
) => {
  return candidateStatus === CandidateStatus.CallRequested;
};

export const canRescheduleCall = (
  candidateStatus: CandidateStatus | null | undefined,
) => {
  return candidateStatus === CandidateStatus.CallCancelled;
};

export const isClientInterviewDone = (slot: DateTimeRange | null) => {
  return slot?.end && isAfter(new Date().toISOString(), slot?.end);
};
