import React, { useCallback } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { Button, Chip, Stack, Typography } from '@mui/material';
import PermContactCalendarRoundedIcon from '@mui/icons-material/PermContactCalendarRounded';
import ThumbUpRoundedIcon from '@mui/icons-material/ThumbUpRounded';
import VideocamRoundedIcon from '@mui/icons-material/VideocamRounded';
import QueryBuilderRoundedIcon from '@mui/icons-material/QueryBuilderRounded';

import {
  AppRoutes,
  CandidateDetailsAction,
  CandidateStatus,
  CandidateStatusLabel,
  SearchParams,
} from '@constants';
import { IPositionCandidate } from '@types';
import { geDateTimeInTimezone, isClientInterviewDone } from '@utils';

interface ICandidateListActionProps {
  candidate: IPositionCandidate;
}

export const CandidateListAction: React.FC<ICandidateListActionProps> = ({
  candidate,
}) => {
  const navigate = useNavigate();

  const handlePassInterviewClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      navigate({
        pathname: `/${AppRoutes.CANDIDATES}/${candidate.id}`,
        search: createSearchParams({
          [SearchParams.ACTION]: CandidateDetailsAction.CLIENT_INTERVIEW_PASSED,
        }).toString(),
      });
    },
    [candidate],
  );

  const handleFailInterviewClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      navigate({
        pathname: `/${AppRoutes.CANDIDATES}/${candidate.id}`,
        search: createSearchParams({
          [SearchParams.ACTION]: CandidateDetailsAction.CLIENT_INTERVIEW_FAILED,
        }).toString(),
      });
    },
    [candidate],
  );

  const renderStatusMessage = () => {
    switch (candidate.status) {
      case CandidateStatus.Submitted:
        return (
          <Button
            size="small"
            variant="contained"
            startIcon={<PermContactCalendarRoundedIcon color="inherit" />}
            sx={{ whiteSpace: 'nowrap', minWidth: 'auto' }}
          >
            Review candidate
          </Button>
        );
      case CandidateStatus.CallScheduled:
        return isClientInterviewDone(candidate.scheduledSlot?.[0] || null) ? (
          <Stack direction="row" gap="0.5rem">
            <Button
              size="small"
              variant="contained"
              startIcon={<ThumbUpRoundedIcon color="inherit" />}
              sx={{ whiteSpace: 'nowrap', minWidth: 'auto' }}
              onClick={handlePassInterviewClick}
            >
              Passed
            </Button>
            <Button
              size="small"
              variant="contained"
              sx={{
                whiteSpace: 'nowrap',
                minWidth: 'auto',
                backgroundColor: 'text.secondary',
                '&:hover': {
                  backgroundColor: 'text.secondary',
                },
              }}
              onClick={handleFailInterviewClick}
            >
              Failed
            </Button>
          </Stack>
        ) : (
          <Chip
            icon={<VideocamRoundedIcon />}
            color="default"
            label={
              !isEmpty(candidate.scheduledSlot) &&
              candidate.scheduledSlot?.[0].start
                ? geDateTimeInTimezone(
                    candidate.scheduledSlot?.[0].start,
                    candidate.clientTimezone,
                  ).format('MMM DD, hh:mm a')
                : CandidateStatusLabel[candidate.status]
            }
          />
        );
      case CandidateStatus.CallRequested:
        return (
          <Chip
            icon={<QueryBuilderRoundedIcon />}
            color="default"
            sx={{
              '.MuiChip-label': {
                paddingRight: 0,
              },
              opacity: 0.8,
            }}
          />
        );
      case CandidateStatus.CallCancelled:
      case CandidateStatus.CallPassed:
      case CandidateStatus.CallFailed:
      case CandidateStatus.Rejected:
      case CandidateStatus.Hired:
      default:
        return <Typography color="text.secondary">—</Typography>;
    }
  };

  return <>{renderStatusMessage()}</>;
};
