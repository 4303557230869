import { RootState } from '@redux/hooks';
import { candidateDetailsStoreKey } from './candidateDetails.const';

const selectCandidateDetailsState = (state: RootState) => {
  return state[candidateDetailsStoreKey];
};

const getCandidateDetailsAction = (state: RootState) => {
  return selectCandidateDetailsState(state).candidateDetailsAction;
};

const getCandidateDetailsData = (state: RootState) => {
  return selectCandidateDetailsState(state);
};

const getCandidateSummary = (state: RootState) => {
  return selectCandidateDetailsState(state).candidateSummary;
};

const getCandidateSummaryViewAPIStatus = (state: RootState) => {
  return selectCandidateDetailsState(state).candidateSummaryApiStatus;
};

export const candidateDetailsSelectors = {
  getCandidateDetailsData,
  getCandidateDetailsAction,
  getCandidateSummary,
  getCandidateSummaryViewAPIStatus,
};
