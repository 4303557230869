import axios from 'axios';

import { createAsyncThunk } from '@reduxjs/toolkit';
import { candidateDetailsStoreKey } from './candidateDetails.const';

import {
  cancelCandidateInterviewAPI,
  ClientFeedbackResolution,
  ClientInterviewFeedbackResolution,
  getCandidateDetailsAPI,
  getCandidateSummaryViewAPI,
  postCandidateFeedbackAPI,
  postInterviewFeedbackAPI,
  rescheduleCandidateInterviewAPI,
} from '@constants';
import { DateTimeRange, ICandidateDetails } from '@types';

export const fetchCandidateDetails = createAsyncThunk(
  `${candidateDetailsStoreKey}/fetchCandidateDetails`,
  async (candidateId: string, { rejectWithValue }) => {
    try {
      const response = await axios.get<ICandidateDetails>(
        getCandidateDetailsAPI(candidateId),
      );

      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);

export const provideCandidateFeedback = createAsyncThunk(
  `${candidateDetailsStoreKey}/provideCandidateFeedback`,
  async (
    {
      candidateId,
      ...data
    }: {
      candidateId: string;
      resolution: ClientFeedbackResolution;
      interviewSlots: DateTimeRange[] | null;
      details: string | null;
      rejectionReasons: string[] | null;
      clientTimezone: string;
      clientCalendar: string | null;
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await axios.post<ICandidateDetails>(
        postCandidateFeedbackAPI(candidateId),
        data,
      );

      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);

export const cancelCandidateInterview = createAsyncThunk(
  `${candidateDetailsStoreKey}/cancelCandidateInterview`,
  async (
    {
      candidateId,
      ...data
    }: {
      candidateId: string;
      details: string | null;
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await axios.post<ICandidateDetails>(
        cancelCandidateInterviewAPI(candidateId),
        data,
      );

      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);

export const rescheduleCandidateInterview = createAsyncThunk(
  `${candidateDetailsStoreKey}/rescheduleCandidateInterview`,
  async (
    {
      candidateId,
      ...data
    }: {
      candidateId: string;
      details: string | null;
      clientCalendar: string | null;
      interviewSlots: DateTimeRange[] | null;
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await axios.post<ICandidateDetails>(
        rescheduleCandidateInterviewAPI(candidateId),
        data,
      );

      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);

export const provideClientInterviewFeedback = createAsyncThunk(
  `${candidateDetailsStoreKey}/provideClientInterviewFeedback`,
  async (
    {
      candidateId,
      ...data
    }: {
      candidateId: string;
      resolution: ClientInterviewFeedbackResolution;
      rejectionReasons: string[] | null;
      details: string | null;
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await axios.post<ICandidateDetails>(
        postInterviewFeedbackAPI(candidateId),
        data,
      );

      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchCandidateSummary = createAsyncThunk(
  `${candidateDetailsStoreKey}/fetchCandidateSummary`,
  async (
    { submissionId, client }: { submissionId: string; client: string | null },
    { rejectWithValue },
  ) => {
    try {
      const response = await axios.get<ICandidateDetails>(
        getCandidateSummaryViewAPI(submissionId),
        {
          params: { client },
        },
      );

      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);
