import { useCallback } from 'react';
import { format } from 'date-fns';
import { useAppDispatch } from '@redux/hooks';

import { Box, Button, Stack, Typography } from '@mui/material';
import { HiringProcessStageLayout } from './HiringProcessStageLayout';

import { setCandidateDetailsAction } from '@redux/candidateDetails';

import {
  CandidateDetailsAction,
  CandidateDetailsInterviewProcessStage,
  CandidateDetailsInterviewProcessStageLabel,
  CandidateDetailsProcessStage,
  ClientInterviewFeedbackResolution,
} from '@constants';
import { ICandidateDetails } from '@types';
import { geDateTimeInTimezone, isClientInterviewDone } from '@utils';

export const InterviewProcessStage: React.FC<{
  data: ICandidateDetails;
  isExpanded: boolean;
  setExpandedStage: (value: CandidateDetailsProcessStage | null) => void;
}> = ({ data, isExpanded, setExpandedStage }) => {
  const dispatch = useAppDispatch();

  const handleActionButtonClick = useCallback(
    (action: CandidateDetailsAction) => {
      dispatch(setCandidateDetailsAction(action));
    },
    [],
  );

  const interviewStage = data.interview?.cancellation
    ? CandidateDetailsInterviewProcessStage.Cancelled
    : data.interview?.result?.resolution ===
        ClientInterviewFeedbackResolution.Passed
      ? CandidateDetailsInterviewProcessStage.Passed
      : data.interview?.result?.resolution ===
          ClientInterviewFeedbackResolution.Failed
        ? CandidateDetailsInterviewProcessStage.Failed
        : data.interview?.scheduledSlot?.[0]
          ? isClientInterviewDone(data.interview?.scheduledSlot?.[0])
            ? CandidateDetailsInterviewProcessStage.Done
            : CandidateDetailsInterviewProcessStage.Scheduled
          : data.interview?.requestedSlot?.[0] || data.interview?.clientCalendar
            ? CandidateDetailsInterviewProcessStage.Requested
            : null;

  if (!interviewStage) return null;

  const date =
    data.interview?.cancellation?.cancelledAt ||
    data.interview?.scheduledSlot?.[0].start ||
    data.interview?.requestedSlot?.[0].start ||
    null;

  return (
    <HiringProcessStageLayout
      title={
        <Box display="flex" gap={1} alignItems="center">
          <Typography
            variant="body1"
            color={
              interviewStage === CandidateDetailsInterviewProcessStage.Failed
                ? 'text.danger'
                : interviewStage ===
                    CandidateDetailsInterviewProcessStage.Passed
                  ? 'text.success'
                  : 'text.primary'
            }
          >
            {CandidateDetailsInterviewProcessStageLabel[interviewStage]}
          </Typography>
          {!![
            CandidateDetailsInterviewProcessStage.Passed,
            CandidateDetailsInterviewProcessStage.Failed,
          ].includes(interviewStage) && data.interview?.result?.reviewer ? (
            <Typography variant="body2" color="text.secondary">
              Interviewed by {data.interview?.result?.reviewer.name}
            </Typography>
          ) : !!date ? (
            <Typography variant="body2" color="text.secondary">
              {geDateTimeInTimezone(
                date,
                data.interview?.clientTimezone || null,
              ).format('DD MMM, hh:mm a')}
            </Typography>
          ) : null}
        </Box>
      }
      actionButton={
        [
          CandidateDetailsInterviewProcessStage.Requested,
          CandidateDetailsInterviewProcessStage.Cancelled,
          CandidateDetailsInterviewProcessStage.Scheduled,
        ].includes(interviewStage) ? (
          <Button
            size="small"
            variant="contained"
            onClick={(e) => {
              e.stopPropagation();
              handleActionButtonClick(
                CandidateDetailsAction.RESCHEDULE_INTERVIEW,
              );
            }}
          >
            Reschedule
          </Button>
        ) : null
      }
      date={date}
      details={
        <Stack gap={2}>
          {data.interview?.result && (
            <>
              {!!data.interview.result.rejectionReason?.length && (
                <Box>
                  <Typography variant="body2" color="text.secondary">
                    Rejection reason
                  </Typography>
                  <Typography variant="body1" whiteSpace="pre-line">
                    {data.interview.result.rejectionReason.join(', ')}
                  </Typography>
                </Box>
              )}
              <Box>
                <Typography variant="body2" color="text.secondary">
                  Feedback
                </Typography>
                <Typography variant="body1" whiteSpace="pre-line">
                  {data.interview.result.feedback}
                </Typography>
              </Box>
              <Box>
                <Typography variant="body2" color="text.secondary">
                  Interviewed by {data.interview.result.reviewer?.name}{' '}
                  {!!data.interview.result.providedAt &&
                    `on ${format(new Date(data.interview.result.providedAt), 'dd MMM')}`}
                </Typography>
              </Box>
            </>
          )}
          {data.interview?.cancellation &&
            [CandidateDetailsInterviewProcessStage.Cancelled].includes(
              interviewStage,
            ) && (
              <>
                {!!data.interview.cancellation?.reason && (
                  <Box>
                    <Typography variant="body2" color="text.secondary">
                      Cancellation reason
                    </Typography>
                    <Typography variant="body1" whiteSpace="pre-line">
                      {data.interview.cancellation?.reason}
                    </Typography>
                  </Box>
                )}
                <Box>
                  <Typography variant="body2" color="text.secondary">
                    Interview cancelled by{' '}
                    {data.interview.cancellation.cancelledBy?.name} on{' '}
                    {format(
                      new Date(data.interview.cancellation.cancelledAt),
                      'dd MMM',
                    )}
                  </Typography>
                </Box>
              </>
            )}
          {!!data.interview?.scheduledSlot?.length &&
            [
              CandidateDetailsInterviewProcessStage.Scheduled,
              CandidateDetailsInterviewProcessStage.Done,
            ].includes(interviewStage) && (
              <Box>
                <Typography variant="body2" color="text.secondary">
                  Scheduled interview slot
                </Typography>
                {data.interview.scheduledSlot.map((slot, index) => (
                  <Typography
                    key={`slot_${index}`}
                    variant="body1"
                    color="text.primary"
                  >
                    {`${geDateTimeInTimezone(
                      slot.start,
                      data.interview!.clientTimezone!,
                    ).format('ddd, MMM DD, hh:mm a')} - ${geDateTimeInTimezone(
                      slot.end,
                      data.interview!.clientTimezone!,
                    ).format('hh:mm a')}`}
                  </Typography>
                ))}
              </Box>
            )}
          {!!data.interview?.clientCalendar &&
            [CandidateDetailsInterviewProcessStage.Requested].includes(
              interviewStage,
            ) && (
              <Box>
                <Typography variant="body2" color="text.secondary">
                  Client calendar
                </Typography>
                <Typography variant="body1" color="text.primary">
                  {data.interview.clientCalendar}
                </Typography>
              </Box>
            )}
          {!!data.interview?.requestedSlot?.length &&
            [CandidateDetailsInterviewProcessStage.Requested].includes(
              interviewStage,
            ) && (
              <Box>
                <Typography variant="body2" color="text.secondary">
                  Requested interview slots
                </Typography>
                {data.interview.requestedSlot.map((slot, index) => (
                  <Typography
                    key={`slot_${index}`}
                    variant="body1"
                    color="text.primary"
                  >
                    {`${geDateTimeInTimezone(
                      slot.start,
                      data.interview?.clientTimezone || null,
                    ).format('ddd, MMM DD, hh:mm a')} - ${geDateTimeInTimezone(
                      new Date(slot.end || '').toISOString(),
                      data.interview?.clientTimezone || null,
                    ).format('hh:mm a')}`}
                  </Typography>
                ))}
              </Box>
            )}
        </Stack>
      }
      isExpanded={isExpanded}
      stage={CandidateDetailsProcessStage.Interview}
      onExpand={setExpandedStage}
    />
  );
};
