import React, { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@redux/hooks';

import {
  BrandLogo,
  CandidateDetailsContent,
  CandidateDetailsHeader,
  CandidateDetailsPageLayout,
  PageWrapper,
} from '@components';
import { Box, Stack, Typography } from '@mui/material';

import {
  candidateDetailsSelectors,
  clearCandidateSummary,
  fetchCandidateSummary,
} from '@redux/candidateDetails';
import { isStatusComplete, isStatusFailed, isStatusLoading } from '@utils';

export const CandidateSummary: React.FC = () => {
  const dispatch = useAppDispatch();
  const { submissionId } = useParams();
  const [searchParams] = useSearchParams();

  const data = useAppSelector(candidateDetailsSelectors.getCandidateSummary);
  const apiStatus = useAppSelector(
    candidateDetailsSelectors.getCandidateSummaryViewAPIStatus,
  );

  useEffect(() => {
    const client = searchParams.get('client');
    if (!submissionId || !client) return;

    dispatch(fetchCandidateSummary({ submissionId, client }));

    return () => {
      dispatch(clearCandidateSummary());
    };
  }, [submissionId]);

  return (
    <PageWrapper isLoading={isStatusLoading(apiStatus)}>
      <Stack
        height="100vh"
        width="100vw"
        position="relative"
        sx={(theme) => ({
          background: theme.palette.background.backgroundPrimary,
        })}
      >
        <Box
          sx={{
            position: { xs: 'relative', md: 'absolute' },
            top: 0,
            left: 0,
          }}
          pt={{ xs: 2, md: 3 }}
          pl={{ xs: 2, md: 6 }}
        >
          <BrandLogo />
        </Box>
        <Stack
          justifyContent="flex-start"
          alignItems="center"
          gap="0.5rem"
          flex={1}
          pt={{ xs: 1, md: 1.5 }}
        >
          {isStatusComplete(apiStatus) && data ? (
            <CandidateDetailsPageLayout
              hideBreadcrumb
              headerComponent={<CandidateDetailsHeader data={data} />}
              contentComponent={
                <CandidateDetailsContent data={data} isViewOnly />
              }
              footerComponent={null}
            />
          ) : (
            <></>
          )}
          {isStatusFailed(apiStatus) && (
            <>
              <Typography variant="h1">
                You are not allowed to see this info
              </Typography>
            </>
          )}
          {isStatusComplete(apiStatus) && !data && (
            <Stack
              justifyContent="center"
              alignItems="center"
              height="100%"
              width="100%"
            >
              <Typography variant="h1">Candidate not found</Typography>
            </Stack>
          )}
        </Stack>
      </Stack>
    </PageWrapper>
  );
};
