export enum ApiStatus {
  FAILED = 'failed',
  IDLE = 'idle',
  LOADING = 'loading',
  COMPLETE = 'complete',
  FORBIDDEN = 'forbidden',
}

// Auth Endpoints
const authControllerBaseAPI = '/auth';

export const loginUserAPI = `${authControllerBaseAPI}/login`;
export const forgotPasswordAPI = `${authControllerBaseAPI}/password/forgot`;
export const resetPasswordAPI = `${authControllerBaseAPI}/password/reset`;
export const refreshTokenAPI = `${authControllerBaseAPI}/refresh-token`;
export const userProfileAPI = `${authControllerBaseAPI}/profile`;

// Positions Endpoints
const posiitonsControllerBaseAPI = '/positions';

export const getPositionsAPI = `${posiitonsControllerBaseAPI}/`;

// Candidates Endpoints
const candidatesControllerBaseAPI = '/candidates';

export const getCandidateDetailsAPI = (id: string) =>
  `${candidatesControllerBaseAPI}/${id}`;
export const postCandidateFeedbackAPI = (id: string) =>
  `${candidatesControllerBaseAPI}/${id}/feedback`;

export const cancelCandidateInterviewAPI = (id: string) =>
  `${candidatesControllerBaseAPI}/${id}/interview/cancel`;

export const rescheduleCandidateInterviewAPI = (id: string) =>
  `${candidatesControllerBaseAPI}/${id}/interview/reschedule`;

export const postInterviewFeedbackAPI = (id: string) =>
  `${candidatesControllerBaseAPI}/${id}/interview/feedback`;

export const getCandidateSummaryViewAPI = (id: string) =>
  `${candidatesControllerBaseAPI}/summary-view/${id}`;
