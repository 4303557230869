export const AppRoutes = {
  HOME: '/',
  SIGN_IN: 'sign-in',
  FORGOT_PASSWORD: 'forgot-password',
  SET_PASSWORD: 'set-password',
  RESET_PASSWORD: 'reset-password',
  CANDIDATES: 'candidates',
  TEAMS: 'teams',
  BILLED_HOURS: 'billed-hours',
  SOW: 'sows',
  SURVEYS: 'surveys',
  REPORT_ISSUE: 'repors-issue',
  CANDIDATE_SUMMARY: 'candidate-summary',
};

export const StandalonePages = [
  AppRoutes.FORGOT_PASSWORD,
  AppRoutes.RESET_PASSWORD,
  AppRoutes.SET_PASSWORD,
  AppRoutes.CANDIDATE_SUMMARY,
];

export const AuthPages = [
  AppRoutes.FORGOT_PASSWORD,
  AppRoutes.RESET_PASSWORD,
  AppRoutes.SET_PASSWORD,
  AppRoutes.SIGN_IN,
];

export const SearchParams = {
  ACTION: 'action',
  REDIRECT: 'redirect',
};
