import React, { useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone.js';

import { MobileDatePicker, MobileTimePicker } from '@mui/x-date-pickers';
import { Stack, StackProps } from '@mui/material';

import { DateTimeRange } from '@types';
import { getDateTimeInUTC, geDateTimeInTimezone } from '@utils';

dayjs.extend(utc);
dayjs.extend(timezone);

interface IDateTimeRangePickerProps extends StackProps {
  timeZone?: string;
  date: DateTimeRange | undefined;
  handleChange: (date: DateTimeRange) => void;
}

export const DateTimeRangePicker: React.FC<IDateTimeRangePickerProps> = ({
  timeZone,
  date = { start: null, end: null },
  handleChange,
  ...rest
}) => {
  const { start, end } = date;

  const [isDatePickerOpen, setDatePickerOpen] = useState<boolean>(false);
  const [isStartTimePickerOpen, setStartTimePickerOpen] =
    useState<boolean>(false);

  const handleDateChange = (
    value: dayjs.Dayjs | null,
    field: 'start' | 'end',
    pickerIndex: number,
  ) => {
    const updatedDate = {
      ...date,
      [field]: value?.toISOString() || null,
      ...(field === 'start' &&
        value && { end: value.add(1, 'hour').toISOString() }),
    };

    handleChange(updatedDate);

    if (pickerIndex === 1) {
      setDatePickerOpen(false);
      setStartTimePickerOpen(true);
    }
  };

  return (
    <Stack direction="row" gap="0.5rem" {...rest}>
      <MobileDatePicker
        open={isDatePickerOpen}
        onClose={() => setDatePickerOpen(false)}
        closeOnSelect
        disablePast
        timezone="UTC"
        label="Day"
        value={start ? geDateTimeInTimezone(start, timeZone!) : null}
        onChange={(date) => {
          handleDateChange(
            getDateTimeInUTC(date?.toISOString() || null, timeZone!),
            'start',
            1,
          );
        }}
        format="MMM DD"
        slotProps={{
          textField: {
            fullWidth: true,
            onClick: () => setDatePickerOpen(true),
          },
        }}
      />
      <MobileTimePicker
        open={isStartTimePickerOpen}
        onClose={() => setStartTimePickerOpen(false)}
        timezone="UTC"
        closeOnSelect
        label="From"
        minutesStep={15}
        value={start ? geDateTimeInTimezone(start, timeZone!) : null}
        onChange={(date) => {
          handleDateChange(
            getDateTimeInUTC(date?.toISOString() || null, timeZone!),
            'start',
            2,
          );
        }}
        format="hh:mm A"
        slotProps={{
          textField: {
            fullWidth: true,
            onClick: () => setStartTimePickerOpen(true),
          },
        }}
      />
      <MobileTimePicker
        timezone="UTC"
        closeOnSelect
        label="To"
        minTime={start ? geDateTimeInTimezone(start, timeZone!) : undefined}
        minutesStep={15}
        value={end ? geDateTimeInTimezone(end, timeZone!) : null}
        onChange={(date) => {
          handleDateChange(
            getDateTimeInUTC(date?.toISOString() || null, timeZone!),
            'end',
            3,
          );
        }}
        format="hh:mm A"
        slotProps={{
          textField: {
            fullWidth: true,
          },
        }}
      />
    </Stack>
  );
};
