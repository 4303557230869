import { ReactNode, useCallback } from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { CandidateDetailsProcessStage } from '@constants';
import { format } from 'date-fns';

export const HiringProcessStageLayout: React.FC<{
  title: ReactNode;
  actionButton: ReactNode | null;
  date: string | null;
  details: ReactNode | null;
  isExpanded: boolean;
  stage: CandidateDetailsProcessStage;
  onExpand: (value: CandidateDetailsProcessStage | null) => void;
}> = ({ title, actionButton, date, details, isExpanded, stage, onExpand }) => {
  const handleExpand = useCallback(
    (event: React.SyntheticEvent, isExpanded: boolean) => {
      if (details) onExpand(isExpanded ? stage : null);
    },
    [details, onExpand],
  );

  return (
    <Accordion
      expanded={isExpanded}
      onChange={handleExpand}
      sx={{ boxShadow: 'none', borderRadius: 'none' }}
    >
      <AccordionSummary
        expandIcon={details && !actionButton ? <ExpandMoreIcon /> : null}
        sx={(theme) => ({
          background: theme.palette.background.backgroundPrimary,
        })}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          gap={2}
          width="100%"
          alignItems="center"
        >
          <Box sx={{ minWidth: '15%' }}>
            <Typography variant="body2" color="text.secondary">
              {stage}
            </Typography>
          </Box>
          <Box flex={1}>{title}</Box>
          <Box>
            {actionButton ? (
              actionButton
            ) : date ? (
              <Typography variant="body2" color="text.secondary">
                {format(new Date(date), 'dd MMM')}
              </Typography>
            ) : null}
          </Box>
        </Box>
      </AccordionSummary>
      {!!details && (
        <AccordionDetails
          sx={(theme) => ({
            background: theme.palette.background.backgroundPrimary,
          })}
        >
          {details}
        </AccordionDetails>
      )}
    </Accordion>
  );
};
