import React, { useState } from 'react';

import { Box, Button, styled, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

interface IExpandableTextProps {
  text: string;
  maxLength?: number;
  children?: React.ReactNode;
}

const GradientBox = styled(Box)({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  height: '6rem',
  background:
    'linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(255, 255, 255 , 255))',
});

export const ExpandableText: React.FC<IExpandableTextProps> = ({
  text,
  maxLength = 300,
  children,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const shouldShowExpandButton = text.length > maxLength;

  return (
    <Box position={'relative'}>
      {isExpanded ? (
        <>
          <Typography whiteSpace="pre-line">{text}</Typography>
          {children}
        </>
      ) : (
        <Typography whiteSpace="pre-line">
          {`${text.slice(0, maxLength)}${shouldShowExpandButton ? '...' : ''}`}
        </Typography>
      )}

      {shouldShowExpandButton && !isExpanded && <GradientBox />}
      {shouldShowExpandButton && (
        <Button
          onClick={toggleExpand}
          variant="text"
          color="info"
          size="small"
          sx={{
            justifyContent: 'end',
            color: 'text.link',
            fontWeight: 400,
            textTransform: 'unset',
            paddingY: '5px',
            paddingX: '8px',
            background: '#E6F2FC',
            marginTop: '12px',
          }}
          endIcon={isExpanded ? <ExpandLessIcon /> : <KeyboardArrowDownIcon />}
        >
          {isExpanded ? 'Show less' : 'Read more'}
        </Button>
      )}
    </Box>
  );
};
