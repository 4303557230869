import { ReactNode } from 'react';
import { format } from 'date-fns';

import {
  CandidateHiringProcess,
  CandidateDetailsFeeback,
  CandidateDetailsProfile,
  CandidateDetailsTestTask,
  CandidateDetailsVideoInterview,
  MatchingPositionProjectList,
} from '@components';
import { Box, Link, Paper, Typography } from '@mui/material';
import { ICandidateDetails, IPerson } from '@types';

const sectionStyle = {
  padding: '20px',
  marginX: 1,
  marginY: 0.5,
  marginTop: 1,
  marginBottom: 0,
  flexDirection: { xs: 'column', md: 'row ' },
};

const sectionStyleFooter = {
  paddingX: '20px',
  paddingY: '8px',
  marginX: 1,
  marginTop: 1,
  flexDirection: { xs: 'column', md: 'row ' },
};

interface ICandidateDetailsContent {
  data: ICandidateDetails;
  isViewOnly?: boolean;
}

export const CandidateDetailsContent: React.FC<ICandidateDetailsContent> = ({
  data,
  isViewOnly = false,
}) => {
  const contentComponents: {
    title?: string;
    content: ReactNode;
    hidden?: boolean;
  }[] = [
    {
      title: 'Feedback from our interviewers',
      content: <CandidateDetailsFeeback data={data} />,
      hidden: !data.feedback,
    },
    {
      title: 'Matching positions for your projects',
      content: <MatchingPositionProjectList data={data} />,
      hidden: !data.positions,
    },
    {
      title: 'Test task',
      content: <CandidateDetailsTestTask data={data} />,
      hidden: !data.testTasks,
    },
    {
      title: 'Profile',
      content: <CandidateDetailsProfile data={data} />,
      hidden: !data.profile,
    },
  ];

  const footerSection = [
    {
      label: 'Sent to',
      content: (
        <Typography variant="body1" color="text.primary">
          {data.recipients
            ?.map((recipient: IPerson) => recipient.name)
            .join(', ')}
        </Typography>
      ),
      isHidden:
        isViewOnly ||
        data.recipients?.every((recipient: IPerson) => !recipient.name),
    },
    {
      label: 'Sent by',
      content: (
        <>
          <Link
            variant="body1"
            color="text.primary"
            href={`mailto:${data.submittedBy?.email}`}
          >
            {data.submittedBy?.name || '-'}
          </Link>
          <Typography variant="body1" color="text.secondary">
            ∙ {format(new Date(data.submittedDate!), 'yyyy-MM-dd')}
          </Typography>
        </>
      ),
    },
  ];

  return (
    <>
      {!isViewOnly && (
        <Paper elevation={5} sx={sectionStyle}>
          <CandidateHiringProcess candidate={data} />
        </Paper>
      )}

      {Boolean(data.videoInterview?.length) && (
        <Box paddingX={1} marginTop={1}>
          <CandidateDetailsVideoInterview data={data} hideQuestions={true} />
        </Box>
      )}

      {contentComponents.map((section, index) => (
        <Paper
          key={index}
          elevation={5}
          sx={sectionStyle}
          hidden={section.hidden}
        >
          <Box>
            <Typography variant="h3" mt={2} mb={2}>
              {section.title}
            </Typography>
            {section.content}
          </Box>
        </Paper>
      ))}
      {footerSection
        .filter((e) => !e.isHidden)
        .map((section, index) => (
          <Box sx={sectionStyleFooter} key={index} display="flex">
            <Typography
              variant="body2"
              color="text.secondary"
              whiteSpace="nowrap"
              width="120px"
            >
              {section.label}
            </Typography>
            <Box display="flex" gap={1} alignItems={'center'} flexWrap="wrap">
              {section.content}
            </Box>
          </Box>
        ))}
    </>
  );
};
