import { HiringProcessStageLayout } from './HiringProcessStageLayout';

import {
  CandidateDetailsProcessStage,
  ClientFeedbackResolution,
} from '@constants';
import { Box, Stack, Typography } from '@mui/material';
import { ICandidateDetails } from '@types';
import { format } from 'date-fns';

export const ProfileProcessStage: React.FC<{
  data: ICandidateDetails;
  isExpanded: boolean;
  setExpandedStage: (value: CandidateDetailsProcessStage | null) => void;
}> = ({ data, isExpanded, setExpandedStage }) => {
  return (
    <HiringProcessStageLayout
      title={
        <Box display="flex" gap={1} alignItems="center">
          <Typography
            variant="body1"
            color={
              !data.clientFeedback?.resolution
                ? 'text.primary'
                : data.clientFeedback?.resolution ===
                    ClientFeedbackResolution.Rejected
                  ? 'text.danger'
                  : 'text.success'
            }
          >
            {!data.clientFeedback?.resolution
              ? 'Submitted'
              : data.clientFeedback?.resolution ===
                  ClientFeedbackResolution.Rejected
                ? 'Rejected'
                : 'Approved'}
          </Typography>
          {!!data.clientFeedback?.reviewer && (
            <Typography variant="body2" color="text.secondary">
              Reviewed by {data.clientFeedback.reviewer.name}
            </Typography>
          )}
        </Box>
      }
      actionButton={null}
      date={data.clientFeedback?.providedAt || data.submittedDate!}
      details={
        data.clientFeedback?.resolution ? (
          <Stack gap={2}>
            {!!data.clientFeedback.rejectionReason?.length && (
              <Box>
                <Typography variant="body2" color="text.secondary">
                  Rejection reason
                </Typography>
                <Typography variant="body1" whiteSpace="pre-line">
                  {data.clientFeedback.rejectionReason.join(', ')}
                </Typography>
              </Box>
            )}
            {!!data.clientFeedback.feedback && (
              <Box>
                <Typography variant="body2" color="text.secondary">
                  Feedback
                </Typography>
                <Typography variant="body1" whiteSpace="pre-line">
                  {data.clientFeedback.feedback}
                </Typography>
              </Box>
            )}
            <Typography variant="body2" color="text.secondary">
              Reviewed by {data.clientFeedback.reviewer?.name}{' '}
              {!!data.clientFeedback.providedAt &&
                `on ${format(new Date(data.clientFeedback.providedAt), 'dd MMM')}`}
            </Typography>
          </Stack>
        ) : null
      }
      isExpanded={isExpanded}
      stage={CandidateDetailsProcessStage.Profile}
      onExpand={setExpandedStage}
    />
  );
};
