import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ApiStatus, CandidateDetailsAction } from '@constants';
import { candidateDetailsStoreKey } from './candidateDetails.const';

import { ICandidateDetailsSliceState } from './candidateDetails.types';
import {
  cancelCandidateInterview,
  fetchCandidateDetails,
  fetchCandidateSummary,
  provideCandidateFeedback,
  provideClientInterviewFeedback,
  rescheduleCandidateInterview,
} from './candidateDetails.thunk';

const initialState: ICandidateDetailsSliceState = {
  apiStatus: ApiStatus.IDLE,
  data: null,
  provideClientFeedbackAPIStatus: ApiStatus.IDLE,
  cancelCandidateInterviewAPIStatus: ApiStatus.IDLE,
  rescheduleCandidateInterviewAPIStatus: ApiStatus.IDLE,
  provideClientInterviewFeedbackAPIStatus: ApiStatus.IDLE,
  candidateDetailsAction: null,
  candidateSummary: null,
  candidateSummaryApiStatus: ApiStatus.IDLE,
};

const candidateDetailsSlice = createSlice({
  name: candidateDetailsStoreKey,
  initialState,
  reducers: {
    clearCandidateDetails: () => initialState,
    clearCandidateSummary: () => initialState,
    setCandidateDetailsAction: (
      state,
      action: PayloadAction<CandidateDetailsAction | null>,
    ) => {
      state.candidateDetailsAction = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCandidateDetails.pending, (state) => {
        state.apiStatus = ApiStatus.LOADING;
      })
      .addCase(fetchCandidateDetails.fulfilled, (state, action) => {
        state.apiStatus = ApiStatus.COMPLETE;
        state.data = action.payload;
      })
      .addCase(
        fetchCandidateDetails.rejected,
        (state, action: PayloadAction<any>) => {
          state.apiStatus =
            action?.payload?.statusCode === 403
              ? ApiStatus.FORBIDDEN
              : ApiStatus.FAILED;
        },
      )
      .addCase(provideCandidateFeedback.pending, (state) => {
        state.provideClientFeedbackAPIStatus = ApiStatus.LOADING;
      })
      .addCase(provideCandidateFeedback.fulfilled, (state, action) => {
        state.provideClientFeedbackAPIStatus = ApiStatus.COMPLETE;
        state.data = action.payload;
      })
      .addCase(provideCandidateFeedback.rejected, (state) => {
        state.provideClientFeedbackAPIStatus = ApiStatus.FAILED;
      })
      .addCase(cancelCandidateInterview.pending, (state) => {
        state.cancelCandidateInterviewAPIStatus = ApiStatus.LOADING;
      })
      .addCase(cancelCandidateInterview.fulfilled, (state, action) => {
        state.cancelCandidateInterviewAPIStatus = ApiStatus.COMPLETE;
        state.data = action.payload;
      })
      .addCase(cancelCandidateInterview.rejected, (state) => {
        state.cancelCandidateInterviewAPIStatus = ApiStatus.FAILED;
      })
      .addCase(rescheduleCandidateInterview.pending, (state) => {
        state.rescheduleCandidateInterviewAPIStatus = ApiStatus.LOADING;
      })
      .addCase(rescheduleCandidateInterview.fulfilled, (state, action) => {
        state.rescheduleCandidateInterviewAPIStatus = ApiStatus.COMPLETE;
        state.data = action.payload;
      })
      .addCase(rescheduleCandidateInterview.rejected, (state) => {
        state.rescheduleCandidateInterviewAPIStatus = ApiStatus.FAILED;
      })
      .addCase(provideClientInterviewFeedback.pending, (state) => {
        state.provideClientInterviewFeedbackAPIStatus = ApiStatus.LOADING;
      })
      .addCase(provideClientInterviewFeedback.fulfilled, (state, action) => {
        state.provideClientInterviewFeedbackAPIStatus = ApiStatus.COMPLETE;
        state.data = action.payload;
      })
      .addCase(provideClientInterviewFeedback.rejected, (state) => {
        state.provideClientInterviewFeedbackAPIStatus = ApiStatus.FAILED;
      })
      .addCase(fetchCandidateSummary.pending, (state) => {
        state.candidateSummaryApiStatus = ApiStatus.LOADING;
      })
      .addCase(fetchCandidateSummary.fulfilled, (state, action) => {
        state.candidateSummaryApiStatus = ApiStatus.COMPLETE;
        state.candidateSummary = action.payload;
      })
      .addCase(fetchCandidateSummary.rejected, (state) => {
        state.candidateSummaryApiStatus = ApiStatus.FAILED;
      });
  },
});

export const {
  clearCandidateDetails,
  clearCandidateSummary,
  setCandidateDetailsAction,
} = candidateDetailsSlice.actions;
export const candidateDetailsSliceReducer = candidateDetailsSlice.reducer;
