import { useState } from 'react';

import { Box } from '@mui/material';
import { ProfileProcessStage } from './components/ProfileProcessStage';
import { InterviewProcessStage } from './components/InterviewProcessStage';

import { ICandidateDetails } from '@types';
import { CandidateDetailsProcessStage } from '@constants';

export const CandidateHiringProcess: React.FC<{
  candidate: ICandidateDetails;
}> = ({ candidate }) => {
  const [expandedStage, setExpandedStage] =
    useState<CandidateDetailsProcessStage | null>(null);

  return (
    <Box
      sx={(theme) => ({
        background: theme.palette.background.backgroundPrimary,
        borderRadius: '2px',
        padding: '8px 24px',
      })}
    >
      <InterviewProcessStage
        data={candidate}
        isExpanded={expandedStage === CandidateDetailsProcessStage.Interview}
        setExpandedStage={setExpandedStage}
      />
      <ProfileProcessStage
        data={candidate}
        isExpanded={expandedStage === CandidateDetailsProcessStage.Profile}
        setExpandedStage={setExpandedStage}
      />
    </Box>
  );
};
