import React from 'react';
import { format } from 'date-fns';

import { Stack, Typography } from '@mui/material';

import { CandidateListingStatusLabel, CandidateStatus } from '@constants';
import { ICandidateDetails } from '@types';
import { isClientInterviewDone } from '@utils';

interface ICandidateStatusInfoProps {
  data: ICandidateDetails;
}

export const CandidateStatusInfo: React.FC<ICandidateStatusInfoProps> = ({
  data,
}) => {
  const renderStatusLabel = (candidate: ICandidateDetails) => {
    if (
      candidate.status === CandidateStatus.CallScheduled &&
      isClientInterviewDone(candidate.interview?.scheduledSlot?.[0] || null)
    ) {
      return 'Interview done';
    } else {
      return CandidateListingStatusLabel[candidate.status];
    }
  };
  return (
    <Stack>
      <Typography variant="body1">{renderStatusLabel(data)}</Typography>
      {!!data.updatedAt && (
        <Typography fontSize="0.688rem" color="text.secondary">
          {format(new Date(data.updatedAt), 'LLL d, hh:mm aa')}
        </Typography>
      )}
    </Stack>
  );
};
